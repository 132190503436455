<template>
  <div class="wameed-dashboard-page-content">
    <page-header
      :title="$t('settings.ad.title')"
      :sub-title="$t('settings.ad.desc')"
    />

    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col
          lg="12"
          md="12"
          class="px-0"
        >
          <div class="w-table d-flex flex-column settings-ads">
            <div class=" settings-ads-grid">
              <div
                class="setting-ad-card-container"
                @click="$refs.imageinput.click()"
              >
                <input
                  ref="imageinput"
                  type="file"
                  accept="image/png, image/jpeg"
                  style="display: none"
                  @change="uploadImageAction($event)"
                >
                <img-upload-icon />
                {{ $t('settings.ad.new_btn') }}
              </div>
              <div
                v-for="ad in getAllAds"
                :key="ad.id"
                class="setting-ad-card-container"
                :style="`background-image: url(${ad.image})`"
              >

                <div
                  class="setting-ad-card-container-delete"
                  @click="showModal=true;adData=ad"
                >
                  <trash3-icon />
                </div>
              </div>
            </div>

          </div>
        </b-col>

        <b-col
          lg="12"
          md="12"
          class="px-0"
        >
          <wameed-pagination
            v-if="getTotalAds"
            v-model="filterData.page"
            :page="filterData.page"
            :total-items="getTotalAds.totalItems"
            :per_page="filterData.per_page"
            @changePage="changePage"
            @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
      <warning-modal
        variant="danger"
        icon-name="trash-icon"
        :visible="showModal"
        :title="$t('settings.ad.modal.delete')"
        :sub-title="$t('settings.ad.modal.delete_desc')"
        :btn-title="$t('common.delete')"
        @close="showModal = false"
        @submitAction="deleteAdData()"
      />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import PageHeader from '@/components/PageHeader.vue';
import WarningModal from '@/components/WarningModal.vue';

export default {
  components: {
    WarningModal,
    WameedPagination,
    PageHeader,

  },
  data() {
    return {
      adData: null,
      showModal: false,
      filterData: {
        page: 1,
        per_page: 20,
      },
    };
  },
  computed: {
    ...mapGetters({
      getAllAds: 'getAllAds',
      getTotalAds: 'getTotalAds',
    }),
  },

  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadAds: 'loadAds',
      addAd: 'addAd',
      uploadFiles: 'uploadFiles',
      deleteAd: 'deleteAd',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadAds(this.filterData);
    },

    uploadImageAction(event, type = 'upload') {
      this.$store.commit('loadingStart', null);
      let files = [];
      if (type === 'upload') {
        files = event.currentTarget.files;
      } else {
        files = event;
      }
      // if (this.dropped == 0) files=event;
      // else files=event.currentTarget.files

      if (!files.length) return;
      const formData = new FormData();


      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);

        this.uploadFiles(formData).then(response => {
          this.form = {
            ...this.form,
            image: response,
          };
          this.addAd({ image: response }).then(() => {
            this.loadData();
          });
          this.$store.commit('loadingFinish');
        });
      }
    },

    deleteAdData() {
      this.deleteAd(this.adData.id).then(() => {
        this.showModal = false;
        this.loadData();
      });
    },
  },
};
</script>
